import { Datum, DatumType } from "../../model/database";
/**
 * DatumとDatumTypeの組み合わせを作る処理
 * @param datums
 * @param datumTypes
 * @returns
 */
export function getDatumDataSet(
    datumTypes: readonly DatumType[],
    datums?: readonly Datum[],
): DatumDataSet[] {
    const dataSet: DatumDataSet[] = [];
    datumTypes.forEach((datumType) => {
        dataSet.push({
            datumType,
            datum: datums?.find((datum) => datum.typeId === datumType.id),
        });
    });
    return dataSet;
}

export type DatumDataSet = {
    datum: Datum | undefined;
    datumType: DatumType;
};
